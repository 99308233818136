import React from 'react';
import i18next from 'i18next';
import getConfig from 'next/config';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'components/Button';
import throttle from 'lodash.throttle';
import { stub as $t } from '@nbcnews/analytics-framework';
import { withViewportVisibility } from 'lib/Hooks/withViewportVisibility';
import {
  package as packagePropType,
  pageRegion as pageRegionPropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';
import { addProductToCart } from 'lib/northfork';
import Breakpoints from 'lib/Breakpoints';
import { getDisplayNameForVertical } from 'lib/vertical';
import { getDataActivityMapID } from 'lib/articleUtils';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  SEE_MORE_BUTTON,
} from 'lib/brandFeatures';

import { DisclaimerModal } from 'components/Disclaimer';
import Link from 'components/Link';
import TitleLogo from 'components/TitleLogo';
import PackageTitle from 'components/PackageTitle';
import { getProductOfferIdsFromProducts, getProductOfferPrices } from 'lib/productOfferPriceHelpers';
import { NotchedHorizontalRule } from 'components/NotchedHorizontalRule';

import { isProductBacon, isShopListByOneProduct } from './Cards/helper';

import { CardSelector } from './Cards/CardSelector';

import styles from './styles.module.scss';

$t('register', 'mbt_bacon_click', { allowDuplicate: true });

const BACON_CARD_WIDTHS = {
  default: 260,
  author: 263,
  oneOneSmall: 260,
  oneOneProduct: 260,
  shopListByOneProduct: 260,
  twoOneSmall: 260,
  twoOneLarge: () => (Breakpoints.isS() ? 260 : 360),
  twoOneLargeItems: () => (Breakpoints.isS() ? 228 : 300),
  twoThreePortrait: 250,
  twoOneLargeVideoPage: () => (Breakpoints.isS() ? 260 : 220),
};

const ADD_ALL_ITEMS_TO_CART_TEXT = 'Add All Items To Cart';

const BACON_BUTTON_WIDTH = 50;

const CARD_GUTTER = 2;

export const appendTrackingIdParams = ({ items, trackingId }) => {
  let modifiedItems = items;

  if (trackingId) {
    const {
      publicRuntimeConfig: {
        CODE_ENVIRONMENT,
      },
    } = getConfig();

    const modifier = CODE_ENVIRONMENT === 'production' ? '' : 'test';

    if (modifier.length > 0) {
      modifiedItems = modifiedItems.map((i) => {
        const offers = i?.item?.offers || [];
        const modifiedOffers = offers.map((o) => (
          { ...o, externalUrl: o.externalUrl.replace(trackingId, `${trackingId}${modifier}`) }
        ));
        return { ...i, item: { ...i.item, offers: modifiedOffers } };
      });
    }
  }
  return modifiedItems;
};

class Bacon extends React.Component {
  static propTypes = {
    content: packagePropType.isRequired,
    pageRegion: pageRegionPropType,
    vertical: PropTypes.string.isRequired,
    trackLink: PropTypes.func,
    onScroll: PropTypes.func,
    shouldShowAdditionalSeller: PropTypes.bool,
    inlineArticle: PropTypes.bool,
    titleBgColor: PropTypes.string,
    titleSize: PropTypes.string,
    useAltTitle: PropTypes.bool,
  };

  static defaultProps = {
    trackLink: Function.prototype,
    onScroll: Function.prototype,
    pageRegion: 'article-body',
    shouldShowAdditionalSeller: false,
    inlineArticle: false,
    titleBgColor: null,
    titleSize: null,
    useAltTitle: false,
  };

  static contextTypes = {
    ...LayoutContextPropType,
  };

  constructor(props) {
    super(props);
    this.isScrolling = false;
    this.scrollContainer = React.createRef();
    this.state = {
      currentPage: 1,
      scrollWidth: 0,
      showPagination: false,
      totalPages: 1,
      addAllToCartText: ADD_ALL_ITEMS_TO_CART_TEXT,
    };

    const { content: { subType } } = props;
    this.isProduct = subType && subType.toLowerCase().indexOf('product') > -1;
    this.disclaimer = null;
  }

  componentDidMount() {
    // Add a breakpoint change listener
    Breakpoints.listenToAll(this.onBreakpointChange);
    const { current: scrollContainer } = this.scrollContainer;
    if (!scrollContainer) {
      return;
    }

    scrollContainer.addEventListener('scroll', this.onContainerScroll);

    const {
      currentPage,
      hasMultiplePages,
      totalPages,
    } = this.getContainerMeasurements();

    if (!Breakpoints.isS() && hasMultiplePages) {
      this.setState({ showPagination: true, currentPage, totalPages });
    }

    // Fix scrollWidth in state until breakpoint change
    const { scrollWidth } = scrollContainer;
    this.setState({ scrollWidth });
  }

  shouldComponentUpdate() {
    return !this.isScrolling;
  }

  componentWillUnmount() {
    // Remove the breakpoint change listener
    Breakpoints.removeFromAll(this.onBreakpointChange);

    const { current } = this.scrollContainer;
    if (current) {
      current.removeEventListener('scroll', this.onContainerScroll);
    }
  }

  onContainerScroll = (e) => {
    const { currentPage, totalPages } = this.state;
    const { current: scrollContainer } = this.scrollContainer;
    const {
      currentPage: newCurrentPage,
      totalPages: newTotalPages,
    } = this.getContainerMeasurements();

    if (
      newCurrentPage !== currentPage
      || newTotalPages !== totalPages
    ) {
      this.setState({
        currentPage: newCurrentPage,
        totalPages: newTotalPages,
      });
    } else if (scrollContainer.scrollLeft === 0) {
      // Rerender to hide the previous button when scrolled to the beginning
      this.setState({});
    }

    const { onScroll } = this.props;
    onScroll(e, {
      x: scrollContainer.scrollLeft + scrollContainer.clientWidth,
      width: scrollContainer.scrollWidth,
    });
  };

  onBreakpointChange = () => {
    if (Breakpoints.isS()) {
      this.setState({ showPagination: false });
    } else {
      const { currentPage, totalPages } = this.getContainerMeasurements();
      this.setState({ showPagination: totalPages > 1, currentPage, totalPages });
    }

    const { scrollWidth } = this.scrollContainer?.current || {};
    if (scrollWidth) {
      this.setState({ scrollWidth });
    }
  }

  getSlideWidth = () => {
    const { content } = this.props;
    const cardType = content && (content.subType || content.cardType);

    if (typeof BACON_CARD_WIDTHS[cardType] === 'function') {
      return BACON_CARD_WIDTHS[cardType]();
    }

    return BACON_CARD_WIDTHS[cardType] || BACON_CARD_WIDTHS.default;
  }

  // eslint-disable-next-line react/destructuring-assignment
  getNumCards = () => (this.props?.content?.items ?? []).filter((c) => c).length

  getContainerMeasurements = () => {
    const {
      scrollWidth,
      showPagination,
    } = this.state;

    const { current: scrollContainer } = this.scrollContainer;

    if (!scrollContainer) return {};

    // Get width from container if value not set in state on mount
    const scrollContainerWidth = scrollWidth || scrollContainer.scrollWidth;

    const {
      clientWidth: containerWidth,
      scrollLeft,
    } = scrollContainer;

    const numCards = this.getNumCards();
    const slideWidth = this.getSlideWidth();

    const firstPageFrameWidth = showPagination
      ? containerWidth
      // account for width of next button if not already rendered
      : containerWidth - BACON_BUTTON_WIDTH;
    const nthPageFrameWidth = showPagination
      ? containerWidth - BACON_BUTTON_WIDTH
      // account for width of next & prev buttons if not already rendered
      : containerWidth - (BACON_BUTTON_WIDTH * 2);
    const numCardsFirstPage = Math.floor(firstPageFrameWidth / (slideWidth + CARD_GUTTER)) || 1;
    const numCardsNthPage = Math.floor(nthPageFrameWidth / (slideWidth + CARD_GUTTER)) || 1;
    const hasMultiplePages = numCards > numCardsFirstPage;
    const widthOfFirstPage = (numCardsFirstPage * (slideWidth + CARD_GUTTER));
    const widthOfNthPage = (numCardsNthPage * (slideWidth + CARD_GUTTER));

    const totalPages = hasMultiplePages
      ? Math.ceil(
        // figure out how many "nth" pages there are, including the last sliver of a card
        (scrollContainerWidth - widthOfFirstPage) / widthOfNthPage,
      ) + 1 // add one for the first page
      : 1;

    let currentPage = 1;
    // Adding a `- 1` check also because when zooming in Chrome/Safari the scrollableContainerWidth is 1 pixel more
    if ((scrollLeft + containerWidth) >= (scrollContainerWidth - 1)) {
      currentPage = totalPages;
    } else {
      currentPage = Math.ceil(scrollLeft / widthOfFirstPage) + 1;
    }

    return {
      containerWidth,
      currentPage,
      hasMultiplePages,
      scrollLeft,
      totalPages,
      widthOfFirstPage,
      widthOfNthPage,
      getTransAmtFromPageNum: (page) => {
        if (page === 1) {
          return 0;
        }
        if (page === totalPages) {
          return scrollWidth - containerWidth;
        }

        return (page - 1) * widthOfNthPage;
      },
    };
  }

  scrollToNextPage = () => {
    const {
      isScrolling,
      scrollWidth,
    } = this.state;

    const {
      current: scrollContainer,
    } = this.scrollContainer;

    if (!scrollContainer || isScrolling) return;

    const {
      containerWidth,
      currentPage,
      hasMultiplePages,
      scrollLeft,
      totalPages,
      widthOfNthPage,
    } = this.getContainerMeasurements();

    if (!hasMultiplePages) return;

    let translateAmount = 0;

    // Adding a `- 1` check also because when zooming in Chrome/Safari the scrollableContainerWidth is 1 pixel more
    if ((scrollLeft + containerWidth) >= (scrollWidth - 1)) {
      // currently on last page, scroll all the way back to start
      translateAmount = 0;
    } else if ((scrollWidth - scrollLeft) - containerWidth < widthOfNthPage) {
      // less than a whole page left, scroll only the remaining amount
      translateAmount = scrollWidth - containerWidth;
    } else {
      translateAmount = widthOfNthPage + scrollLeft;
    }

    this.scrollTo(translateAmount);
    this.setState({
      currentPage: currentPage === totalPages ? 1 : currentPage + 1,
      totalPages,
    });
  }

  scrollTo = (left) => {
    const { current: scrollContainer } = this.scrollContainer;
    this.isScrolling = true;
    scrollContainer.scrollTo({
      behavior: 'smooth',
      left,
    });
    setTimeout(() => {
      this.isScrolling = false;
      this.setState({});
    }, 250);
  }

  scrollToPrevPage = () => {
    const { current: scrollContainer } = this.scrollContainer;

    if (!scrollContainer) return;

    const {
      hasMultiplePages,
      scrollLeft,
      widthOfFirstPage,
      widthOfNthPage,
      currentPage,
      totalPages,
    } = this.getContainerMeasurements();

    if (!hasMultiplePages || scrollLeft === 0) return;

    let translateAmount = 0;

    if (scrollLeft <= (widthOfFirstPage - BACON_BUTTON_WIDTH)) {
      // currently on 2nd page, scroll back to the start
      translateAmount = 0;
    } else {
      const amountScrolledPastFirstPage = scrollLeft - (widthOfFirstPage - BACON_BUTTON_WIDTH);
      // if there's 0 left over, we're currently at the start of a page and
      if (amountScrolledPastFirstPage < 0) {
        translateAmount = 0;
      } else {
        // we need to scroll back one whole page
        translateAmount = scrollLeft - widthOfNthPage;
      }
    }

    // if you're on the 2nd page and not scrolling all the way back to the start,
    // it's still the 2nd page
    const newCurrentPage = (currentPage === 2 && translateAmount !== scrollLeft)
      // or if you're not on the 2nd page, and not on the last page, and you're scrolling
      // less than the width of a whole page, you're still on the same page
      || (currentPage !== 2 && currentPage !== totalPages && translateAmount < widthOfNthPage)
      ? currentPage
      : (currentPage - 1) || 1;
    this.scrollTo(translateAmount);
    this.setState({
      currentPage: newCurrentPage,
      totalPages,
    });
  }

  scrollToPage = (pageNum) => {
    const { getTransAmtFromPageNum } = this.getContainerMeasurements();
    this.scrollTo(getTransAmtFromPageNum(pageNum));
    this.setState({
      currentPage: pageNum,
    });
  }

  setMbtBaconClickTracking = (object) => {
    const {
      content,
    } = this.props;

    $t('track', 'mbt_bacon_click', { ...object, content });
  };

  renderTitle = () => {
    const {
      content: {
        metadata,
        subType,
      },
      titleBgColor,
      titleSize,
      useAltTitle,
      inlineArticle,
      vertical,
    } = this.props;

    const { isFluidWidthPage } = this.context;

    const {
      logoUrl,
      seeAllText,
      seeAllUrl,
      title,
      icid,
      anchorId,
    } = metadata || {};


    const shouldRenderButton = getFeatureConfigForBrand(
      SEE_MORE_BUTTON,
      vertical,
    );
    const showSeeAll = !isShopListByOneProduct(subType) && !shouldRenderButton && !!seeAllUrl;
    const titleClass = classNames(styles.baconTitle, {
      [styles.shopList]: isShopListByOneProduct(subType),
    });

    if (!(title || logoUrl)) return null;

    const pkgTitleClassNames = classNames('bacon-pkg-title', {
      'bacon-pkg-title--inline-article': inlineArticle,
    });

    return (
      <div className={styles.baconHeaderHeadline}>
        <button
          onClick={() => this.setMbtBaconClickTracking({
            action: 'navClick',
            type: 'bacon-header',
          })}
          type="button"
          className={styles.baconHeaderHeadlineBtn}
          data-test="bacon__header__headline"
          data-testid="bacon__header"
        >

          {(title || logoUrl) && (
            (useAltTitle || isFluidWidthPage) ? (
              <PackageTitle
                metadata={{
                  title, seeAllUrl, icid, logoUrl, anchorId,
                }}
                titleBgColor={titleBgColor}
                size={titleSize}
                className={pkgTitleClassNames}
              />
            ) : (
              <h2
                className={titleClass}
                data-testid="bacon__headline"
              >
                <>
                  <TitleLogo logoUrl={logoUrl} title={title} />
                  {showSeeAll ? (
                    <Link
                      className={styles.baconTitleLink}
                      data-testid="bacon__header__see-all-link"
                      to={seeAllUrl}
                      icid={icid}
                    >
                      {seeAllText || i18next.t('SEE ALL')}
                    </Link>
                  ) : null}
                </>
              </h2>
            )
          )}
        </button>
      </div>
    );
  }

  hasDisclaimer = () => {
    const {
      content: {
        metadata,
        subType,
      },
    } = this.props;

    const {
      componentTitle,
      disclaimerKey,
      disclaimerOverride = false,
      ecommerceEnabled = true,
      shortDisclaimer = null,
    } = metadata || {};

    if (disclaimerKey === 'NONE') return null;

    if (componentTitle === 'curated-list') {
      if (shortDisclaimer?.length > 0) {
        this.disclaimer = shortDisclaimer;
      }
      return disclaimerOverride && ecommerceEnabled && subType && subType.toLowerCase().includes('product');
    }
    return ecommerceEnabled && subType && subType.toLowerCase().includes('product');
  }

  /**
   * Render description.
   */
  renderDescription = () => {
    const {
      content: {
        subType,
        metadata,
      },
      inlineArticle,
    } = this.props;

    const {
      componentTitle,
      description,
      seeAllText,
      seeAllUrl,
    } = metadata || {};

    if (!description) return null;

    const isCuratedList = componentTitle === 'curated-list';

    const isCurationProductBacon = isProductBacon(subType) && !isCuratedList;

    if (isShopListByOneProduct(subType) || isCurationProductBacon) {
      return (
        <div className={styles.description} data-testid="bacon__description--read-more">
          {description}
          {seeAllUrl && <Link className={styles.readMoreLink} to={seeAllUrl}>{seeAllText || i18next.t('READ MORE')}</Link>}
        </div>
      );
    }

    if (inlineArticle) {
      return (
        <div
          className={styles.description}
          data-testid="bacon__description--rich-text"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
        />
      );
    }

    return <div className={styles.description} data-testid="bacon__description">{description}</div>;
  }

  renderAllItemsToCartButton = () => {
    const {
      content: {
        items,
        metadata,
      },
    } = this.props;
    const { trackingId } = metadata || {};
    const { addAllToCartText } = this.state;

    const productIds = getProductOfferIdsFromProducts(items);

    const itemsForTracking = items
      .filter((i) => Boolean(i) && Boolean(i.item))
      .map((i) => {
        const { item } = i;
        const { name, offers } = item || {};
        const [offer = {}] = offers ?? [];
        const priceMap = getProductOfferPrices(offer);
        const seller = offer?.seller?.name ?? null;
        const listPrice = priceMap?.list ?? null;
        const salePrice = priceMap?.sale ?? null;
        return {
          name,
          seller,
          listPrice,
          salePrice,
        };
      });
    return (
      <button
        type="button"
        className={styles.allItemsToCart}
        onClick={() => {
          $t('track', 'mbt_bacon_click', {
            action: 'addAllItemsToCart',
            items: itemsForTracking,
          });
          addProductToCart(productIds, { trackingId });
          this.setState({ addAllToCartText: 'Added All Items To Cart' });
          setTimeout(() => this.setState({
            addAllToCartText: ADD_ALL_ITEMS_TO_CART_TEXT,
          }), 600);
        }}
      >
        {addAllToCartText}
      </button>
    );
  }

  renderDisclaimer = () => {
    if (!this.hasDisclaimer()) return null;
    const {
      content: {
        metadata,
      },
      vertical,
    } = this.props;
    const { disclaimerKey, disclaimerOverride, hideDisclaimerLearnMore } = metadata || {};
    return (
      <DisclaimerModal
        disclaimerKey={disclaimerKey}
        disclaimerOverride={{ disclaimerOverride }}
        showCloseButton
        hideLearnMore={hideDisclaimerLearnMore}
        small
      >
        <>
          {this.disclaimer ?? `${getDisplayNameForVertical(vertical)} earns a commission on purchases through these links.`}
        </>
      </DisclaimerModal>
    );
  }

  renderPagingDots = () => {
    const { currentPage, totalPages, showPagination } = this.state;

    if (!showPagination) {
      return null;
    }

    return (
      <div
        className={styles.baconPagingDots}
        data-testid="bacon__paging-dots"
      >
        {Array.from({ length: totalPages }).map((_, i) => {
          const dotPageNum = i + 1;
          const isCurrentPage = currentPage === dotPageNum;

          return (
            <button
              type="button"
              aria-label="Pagination"
              key={`${dotPageNum}/${totalPages}`}
              onClick={() => {
                this.scrollToPage(dotPageNum);
                this.setMbtBaconClickTracking({
                  action: 'navClick',
                  type: `pagingDotNumber: ${dotPageNum}`,
                });
              }}
              data-testid="bacon__header__paging-dot"
              className={classNames(
                styles.baconPagingDot,
                {
                  [styles.baconPagingDotIsCurrent]: isCurrentPage,
                },
              )}
            />
          );
        })}
      </div>
    );
  }

  renderPrevButton = () => {
    const { showPagination } = this.state;
    const { current } = this.scrollContainer || {};
    const { scrollLeft = 0 } = current || {};

    if (!scrollLeft) return null;

    return (
      <div
        className={classNames(
          styles.baconPrevBtnContainer,
          { [styles.baconPrevBtnActive]: showPagination && scrollLeft !== 0 },
        )}
        data-testid="bacon__page-btns__prev-container"
      >
        <button
          type="button"
          className={styles.baconPageBtnPrev}
          onClick={() => {
            this.scrollToPrevPage();
            this.setMbtBaconClickTracking({
              action: 'navClick',
              type: 'left arrow',
            });
          }}
          data-testid="bacon__page-btns__prev"
        >
          <span className={classNames(styles.baconPageBtnIcon, 'icon icon-angle-down')} />
        </button>
      </div>
    );
  }

  renderNextButton = () => {
    const { showPagination } = this.state;

    return (
      <div
        className={classNames(
          styles.baconNextBtnContainer,
          { [styles.baconNextBtnContainerNoPagination]: !showPagination },
        )}
        data-testid="bacon__page-btns__next-container"
      >
        <button
          type="button"
          className={styles.baconPageBtnNext}
          onClick={throttle(() => {
            this.scrollToNextPage();
            this.setMbtBaconClickTracking({
              action: 'navClick',
              type: 'right arrow',
            });
          }, 250, { leading: true, trailing: true })}
          data-testid="bacon__page-btns__next"
        >
          <span className={classNames(styles.baconPageBtnIcon, 'icon icon-angle-down')} />
        </button>
      </div>
    );
  }

  renderSeeMoreButton = () => {
    const {
      content: {
        metadata,
        subType,
      },
    } = this.props;

    const {
      seeAllText,
      seeAllUrl,
    } = metadata || {};

    const showSeeAll = !isShopListByOneProduct(subType) && seeAllUrl;

    return (
      showSeeAll && (
        <div>
          <Button
            size="normal"
            alignment="right"
            title={seeAllText || i18next.t('See More')}
            type="link"
            url={seeAllUrl}
          />
        </div>
      )
    );
  }

  renderFooter() {
    const {
      content: {
        subType,
        metadata,
      },
    } = this.props;
    const { sponsorLogoText, sponsorLogoUrl } = metadata || {};
    if (isShopListByOneProduct(subType) && sponsorLogoText && sponsorLogoUrl) {
      return (
        <div className={styles.footer} data-testid="bacon__footer--sponsor">
          <div>{sponsorLogoText}</div>
          <img className={styles.logo} alt="sponsor-logo" src={sponsorLogoUrl} />
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      content,
      inlineArticle,
      content: {
        id,
        items: unfilteredItems,
        subType,
        metadata,
      },
      vertical,
      pageRegion,
      shouldShowAdditionalSeller,
      trackLink,
    } = this.props;

    const {
      description,
      title,
      componentTitle,
      trackingId,
      showBottomBorder = false,
    } = metadata || {};

    const isCuratedList = componentTitle === 'curated-list';
    const isCurationProductBacon = isProductBacon(subType) && !isCuratedList;
    const items = unfilteredItems.filter(
      (i) => Boolean(i) && (i.type !== 'product' || (Boolean(i.id) && Boolean(i.item))),
    );

    if (items.length === 0) {
      return null;
    }

    const modifiedItems = appendTrackingIdParams({
      items,
      trackingId,
    });

    const { showPagination } = this.state;

    const hasDisclaimer = this.hasDisclaimer();
    const hasDescription = hasDisclaimer || !!description;
    const showItemDescriptions = Boolean(
      modifiedItems.find(
        (i) => i.item?.description?.primary || i.computedValues?.dek,
      ),
    );

    const dataActivityMapID = getDataActivityMapID(
      {
        componentName: 'bacon',
        pageRegion,
        componentTitle: componentTitle || title,
      },
    );

    const slideWidthClass = content.subType ?? content.cardType;
    const isTwoOneLargeItem = content.subType === 'twoOneLargeItems';
    const shouldRenderButton = getFeatureConfigForBrand(
      SEE_MORE_BUTTON,
      vertical,
    );
    return (
      <section
        className={classNames({
          [styles.stripe]: subType?.includes('Product'),
          isShowingPagination: showPagination,
          [styles.inlineArticle]: inlineArticle,
          [styles.twoOneLargeItemsBacon]: isTwoOneLargeItem,
        }, styles.baconPackage, 'pkg bacon')}
        data-packageid={id}
        data-activity-map={dataActivityMapID}
        data-testid="baconPackage"
      >
        <NotchedHorizontalRule className={styles.baconHeaderTopBorder} data-testid="bacon__header__top-border" />
        <div
          className={classNames(styles.baconHeader, {
            [styles.baconHeaderFull]: hasDescription,
            [styles.centerAlign]: isShopListByOneProduct(subType),
            [styles.flexColumn]: !hasDescription,
          })}
        >
          {this.renderTitle()}
          <div className={styles.baconHeaderInner}>
            {
              isCurationProductBacon && (
                <div
                  className={styles.descriptionWrapper}
                  data-testid="bacon__header__dek"
                >
                  {this.renderDescription()}
                  {this.renderDisclaimer()}
                  <div className={styles.baconAddButtonPagingDots}>
                    {this.renderPagingDots()}
                  </div>
                </div>
              )
            }
            {
              isShopListByOneProduct(subType) && (
                <div
                  className={styles.descriptionWrapper}
                  data-testid="bacon__header__dek"
                >
                  {this.renderDescription()}
                  {this.renderDisclaimer()}
                  <div className={styles.baconAddButtonPagingDots}>
                    {this.renderAllItemsToCartButton()}
                    {this.renderPagingDots()}
                  </div>
                </div>
              )
            }
            {
              inlineArticle && (
                <div
                  className={styles.descriptionWrapper}
                  data-testid="bacon__header__dek"
                >
                  {this.renderDescription()}
                  <div className={styles.baconDisclaimerPagingDots}>
                    {this.renderDisclaimer()}
                    {this.renderPagingDots()}
                  </div>
                </div>
              )
            }
            {
              !inlineArticle
              && !isShopListByOneProduct(subType)
              && !isCurationProductBacon
              && hasDisclaimer
              && (
                <div
                  className={styles.descriptionWrapper}
                  data-testid="bacon__header__dek"
                >
                  <div className={styles.baconDisclaimerPagingDots}>
                    {this.renderDisclaimer()}
                    {this.renderPagingDots()}
                  </div>
                </div>
              )
            }
            {
              !inlineArticle
              && !isShopListByOneProduct(subType)
              && !isCurationProductBacon
              && !hasDisclaimer
              && (
                <>
                  {this.renderDescription()}
                  {this.renderPagingDots()}
                </>
              )
            }
          </div>
        </div>
        <div
          className={classNames(styles.baconScrollWrapper, {
            [styles.centerAlign]: isShopListByOneProduct(subType),
          })}
        >
          {this.renderPrevButton()}
          <div data-testid="bacon-scroll-container" className={styles.baconScrollContainer} ref={this.scrollContainer}>
            {modifiedItems.map((itemObj, index) => {
              if (!itemObj || !itemObj?.computedValues) return null;
              const indexOffset = index + 1;
              // the id can either be a direct child or will be inside a nested object
              const keyVal = itemObj?.item?.id ?? itemObj.id;
              const item = itemObj?.item ?? itemObj;
              return (
                <CardSelector
                  key={keyVal}
                  item={itemObj}
                  shouldShowAdditionalSeller={shouldShowAdditionalSeller}
                  totalNumberOfCards={modifiedItems.length}
                  indexOffset={indexOffset}
                  showItemDescriptions={showItemDescriptions}
                  content={content}
                  trackLink={() => trackLink(item)}
                  onAddProduct={(productId) => addProductToCart([productId], { trackingId })}
                  containerClasses={classNames(styles.baconCardWrapper, styles[slideWidthClass], {
                    lastItem: index === modifiedItems.length - 1,
                  })}
                  setMbtBaconClickTracking={this.setMbtBaconClickTracking}
                  vertical={vertical}
                />
              );
            })}
          </div>
          {this.renderNextButton()}
        </div>
        {this.renderFooter()}
        {showBottomBorder && (<hr className={styles.baconBottomBorder} data-testid="bacon__bottom-border" />)}
        {shouldRenderButton && this.renderSeeMoreButton()}
      </section>
    );
  }
}
const LazyBacon = withViewportVisibility(Bacon, '300px');
export { Bacon, LazyBacon };
